import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import JobRolesTable from "./components/JobRolesTable";
import JobRoleCategeory from "./components/JobRoleCategeory";
import JobRoleQuestions from "./components/JobRoleQuestions";
import Login from "./components/Auth/Login";
import Dashboard from "./Dashboard";
import JobTypeSelector from "./components/JobTypeSelector";
import AdminDashboard from "./components/Admin/AdminDashboard";
import PrivateRoute from "./PrivateRoute";
import Remarks from "./components/Remarks";
import RoleTables from "./components/RoleTables";
import ProfileComponent from "./components/ProfileComponent";
import AiTraining from "./components/AiTraining";
import JobRoleDetails from "./components/Admin/JobRoleDetails";
import RemarksEdit from "./components/RemarksEdit";
import GenralQuestions from "./components/GeneralQuestions";
import DisplayGeneralQuestions from "./components/DisplayGeneralQuestions";
import NavBar from "./components/common/NavBar";

const AppContent = () => {
  const location = useLocation(); // Get the current route path

  return (
    <div>
      {/* Show NavBar only if username exists and the path is not "/" */}
      {location.pathname !== "/" && <NavBar />}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admindashboard"
          element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/approval"
          element={
            <PrivateRoute>
              <JobRoleDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/type"
          element={
            <PrivateRoute>
              <JobTypeSelector />
            </PrivateRoute>
          }
        />
        <Route
          path="/allroles"
          element={
            <PrivateRoute>
              <RoleTables />
            </PrivateRoute>
          }
        />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<ProfileComponent />} />
        <Route path="/roles" element={<JobRolesTable />} />
        <Route path="/category" element={<JobRoleCategeory />} />
        <Route path="/questions" element={<JobRoleQuestions />} />
        <Route path="/remarks" element={<Remarks />} />
        <Route path="/aitraining" element={<AiTraining />} />
        <Route path="/generalquestions" element={<GenralQuestions />} />
        <Route path="/remarksedit" element={<RemarksEdit />} />
        <Route path="/display" element={<DisplayGeneralQuestions />} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
