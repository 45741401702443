import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  TextField,
  Chip,
  Box,
  Snackbar,
  Autocomplete,
  Alert,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the ArrowBack icon
import { useNavigate } from "react-router-dom"; // For navigation

const JobRoleCategeory = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [requirementOptions, setRequirementOptions] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [jobRequirements, setjobRequirements] = useState([]);
  const [selectedRequirements, setSelectedRequirements] = useState([]); // For multiple select
  const [errorMessage, setErrorMessage] = useState(""); // For Snackbar error message
  const [openSnackbar, setOpenSnackbar] = useState(false); // To control Snackbar visibility
  const [prioritizedOptions, setPrioritizedOptions] = useState([]);

  const navigate = useNavigate(); // For navigation to the dashboard

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "categorised,role_requirements_pending",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        {
          headers,
        }
      );

      console.log("API response data:", response.data);

      if (
        response.data &&
        response.data.role &&
        typeof response.data.role === "object" &&
        !Array.isArray(response.data.role)
      ) {
        const formattedRequirements =
          response.data.requirements?.map((req) => ({
            all_requirements: req, // Ensure structure is { all_requirements: "requirement text" }
          })) || []; // Default to "No Requirements" if no data

        setjobRequirements(formattedRequirements);
        const role = {
          id: response.data.role.id,
          title: response.data.role.name || "Job Role",
          description:
            response.data.role.description || "No description provided",
          category: response.data.role.category || "Uncategorized",
          status: response.data.role.status || "Fetched",
        };

        setJobRoles([role]);
        setRoleId(role.id); // Wrap the role in an array
        setRoleId(role.id);
      } else {
        console.error("Unexpected data structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching job roles:", error);
      setErrorMessage("Error fetching job roles. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchJobRoles();
  //   console.log(requirementOptions);
  // }, []);

  const handleSave = async () => {
    const handleunassign = async () => {
      // Fetch username and password from localStorage
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");

      // API endpoint
      const url =
        "https://management.plugleads.in/role_corpus_data/unassign-role/unassign_role/";

      try {
        // Make a POST request with Basic Auth
        const response = await axios.post(
          url,
          { role_id: roleId },
          {
            auth: {
              username,
              password,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Roles unassigned successfully:", response.data);
        return response.data;
      } catch (error) {
        handleError(error);
      }
    };
    const handleError = (error) => {
      // Assuming error.response?.data contains an object with a `detail` property
      const errorDetail = error.response?.data?.detail || error.message; // Get the specific message

      console.error("Error unassigning roles:", errorDetail);
      setErrorMessage(errorDetail); // Store only the string message
    };
    await handleunassign();
    await fetchJobRoles();
  };

  const sortedJobRequirements = jobRequirements
    .map((req) => req.all_requirements)
    .filter((req) => typeof req === "string" && req.trim() !== "")
    .sort((a, b) => a.localeCompare(b));

  console.log("Sorted Job Requirements:", sortedJobRequirements);

  const filteredOptions = sortedJobRequirements.filter(
    (req) =>
      typeof req === "string" &&
      req.toLowerCase().includes(searchQuery?.toLowerCase() || "")
  );

  useEffect(() => {
    if (filteredOptions && sortedJobRequirements) {
      const newOptions = [
        ...filteredOptions,
        ...sortedJobRequirements.filter(
          (req) => !filteredOptions.includes(req)
        ),
      ];

      // ✅ Prevent infinite loop by checking if the state is actually changing
      if (JSON.stringify(newOptions) !== JSON.stringify(prioritizedOptions)) {
        setPrioritizedOptions(newOptions);
      }
    }
  }, [filteredOptions, sortedJobRequirements]);

  const handleRequirementChange = (event, newValue) => {
    const uniqueValues = [...new Set(newValue)]; // Ensure uniqueness
    setSelectedRequirements(uniqueValues);

    // ✅ Prevent infinite loop by only adding new options
    const newOptions = uniqueValues.filter(
      (req) => !prioritizedOptions.includes(req)
    );

    if (newOptions.length > 0) {
      setPrioritizedOptions((prev) => [...prev, ...newOptions]);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedRequirements(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery((prev) => {
      console.log(event.target.value, "HTHT");
      return event.target.value;
    });
  };

  const handlefetchrole = async () => {
    await fetchJobRoles();
  };

  const handleNext = async () => {
    let validationErrors = {};

    if (selectedRequirements.length === 0) {
      validationErrors.selectedRequirements =
        "Please select at least one requirement.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const category = localStorage.getItem("usertype");
    const userid = localStorage.getItem("userid");

    const requestData = {
      id: jobRoles[currentRoleIndex]?.id,
      role_requirements: selectedRequirements,
      username: username,
      userid: userid,
      category,
    };

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/update-role/Mapping/",
        requestData,
        {
          auth: {
            username: `${username}`,
            password: `${password}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Data posted successfully:", response.data);

        setSelectedRequirements([]);

        if (currentRoleIndex < jobRoles.length - 1) {
          setCurrentRoleIndex((prevIndex) => prevIndex + 1);
          setFieldErrors({});
        } else {
          console.log("No more job roles available.");
        }
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setErrorMessage("There was an error posting the data. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard"); // Navigate to the dashboard route
  };

  if (currentRoleIndex >= jobRoles.length) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            height: "fit-content",
            gap: "20px",
          }}
        >
          <IconButton
            onClick={() => navigate("/dashboard")}
            color="primary"
            style={{ marginBottom: "0px", fontSize: "23px" }}
            sx={{
              "&:hover": {
                backgroundColor: "transparent", // Disables hover background
              },
            }}
          >
            <ArrowBackIcon /> Back To Dashboard
          </IconButton>{" "}
          <Button
            variant="outlined"
            color="primary"
            onClick={handlefetchrole}
            sx={{ textTransform: "none", padding: "2px" }}
          >
            Fetch Job Role
          </Button>
        </Box>
      </div>
    );
  }

  const currentRole = jobRoles[currentRoleIndex];

  return (
    <div style={{ margin: "auto" }}>
      {/* Back to Dashboard Button */}
      <IconButton
        onClick={() => navigate("/dashboard")}
        color="primary"
        style={{ marginBottom: "20px", fontSize: "23px" }}
        sx={{
          "&:hover": {
            backgroundColor: "transparent", // Disables hover background
          },
        }}
      >
        <ArrowBackIcon /> Back To Dashboard
      </IconButton>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          alignItems: "start",
          justifySelf: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "50%", padding: "20px" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontSize: "40px", fontWeight: "900px", color: "DarkBlue" }}
          >
            {currentRole.title}
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              style={{ marginTop: "5px", fontSize: "20px" }}
            >
              <strong style={{ marginBottom: "0px" }}>Requirements:</strong>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
                {jobRequirements && jobRequirements.length > 0 ? (
                  jobRequirements.map((req, index) => (
                    <Chip key={index} label={req.all_requirements} />
                  ))
                ) : (
                  <Typography variant="body2">
                    No requirements available
                  </Typography>
                )}
              </Box>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: "50%", padding: "20px" }}>
          <Typography
            sx={{ marginTop: "20px", marginBottom: "-15px", fontSize: "14px" }}
          >
            Select The Job Requirement
          </Typography>

          <FormControl fullWidth style={{ marginTop: "20px" }}>
            <Autocomplete
              multiple
              freeSolo // This allows the user to input new values
              options={prioritizedOptions}
              value={selectedRequirements}
              onChange={handleRequirementChange} // Handles the change
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onInputChange={(event, newInputValue) => {
                // Handle any changes in the input text field
                setSearchQuery(newInputValue); // Update the search query for filtering
              }}
            />
          </FormControl>

          <div
            style={{
              marginTop: "20px",
              textAlign: "right",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Button variant="contained" color="primary" onClick={handleNext}>
              Save
            </Button>
            <Button variant="outlined" color="primary" onClick={handleSave}>
              Next
            </Button>
          </div>
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default JobRoleCategeory;
