import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Remarks = () => {
  const [remarksData, setRemarksData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remarks, setRemarks] = useState();
  const [error, setError] = useState(null);
  const [editedRemarkId, setEditedRemarkId] = useState(null); // Track the edited remark ID
  const navigate = useNavigate();

  // Fetch remarks data using Basic Auth
  useEffect(() => {
    const fetchRemarks = async () => {
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");

      const url =
        "https://management.plugleads.in/role_corpus_management/users/users_with_remarks/";
      const auth = {
        username: `${username}`,
        password: `${password}`,
      };

      try {
        const response = await axios.get(url, {
          auth,
        });
        setRemarksData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchRemarks();
  }, []);

  const handleEdit = (remark) => {
    setEditedRemarkId(remark.role_id); // Mark the remark as being edited
    // Navigate to the edit component with state
    navigate("/remarksedit", { state: { remark } });
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ padding: "16px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          height: "fit-content",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          style={{ marginBottom: "0px", fontSize: "23px" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Disables hover background
            },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>{" "}
      </Box>
      <Typography variant="h4" sx={{ marginBottom: "16px", color: "#2C3E50" }}>
        User Remarks
      </Typography>
      {remarksData.map((user) => (
        <Box key={user.id} sx={{ marginBottom: "24px" }}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "8px",
              fontWeight: "bold",
              color: "#34495E",
            }}
          >
            Username: {user.username}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Role ID</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Role</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Remarks</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.remarks.map((remark, index) => (
                  <TableRow key={index}>
                    <TableCell>{remark.role_id}</TableCell>
                    <TableCell>{remark.role_name || "N/A"}</TableCell>
                    <TableCell>{remark.remarks}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(remark)}
                        disabled={editedRemarkId === remark.role_id} // Disable the button if it's being edited
                      >
                        {editedRemarkId === remark.role_id
                          ? "Editing..."
                          : "Edit"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

export default Remarks;
