import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Alert,
  Card,
  CardContent,
  IconButton,
  Snackbar,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom"; // React Router hook for navigation

const getBorderStyle = (level) => {
  switch (level) {
    case 2:
      return "2px solid #7b4397"; // Level 2: Solid purple
    case 3:
      return "2px dashed #dc2430"; // Level 3: Dashed red
    case 4:
      return "2px dotted #ff9800"; // Level 4: Dotted orange
    default:
      return "2px solid #4caf50"; // Level 1 or any others: Solid green
  }
};

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 3),
}));

// Styled card for each question block
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: "#fff",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.12)",
  },
}));

const formatQuestionPayload = (question, index) => {
  if (!question) return null;

  return {
    [index]: {
      variations: question.variations
        .map((v) => v.text)
        .filter((text) => text.trim() !== ""), // Remove empty text values
      type: question.type,
      variable: question.variable,
      yes: question.yes
        ? formatQuestionPayload(question.yes, index + ".1")
        : null, // Recursive call for nested "Yes" branches
    },
  };
};

const QuestionForm = ({ question, onChange, label, level = 1 }) => {
  const handleTypeChange = (event) => {
    onChange({ ...question, type: event.target.value });
  };

  const handleVariableChange = (event) => {
    onChange({ ...question, variable: event.target.value });
  };

  const handleVariationChange = (index, value) => {
    const newVariations = [...question.variations];
    newVariations[index] = { text: value };
    onChange({ ...question, variations: newVariations });
  };

  const addVariation = () => {
    onChange({
      ...question,
      variations: [...question.variations, { text: "" }],
    });
  };

  const removeVariation = (index) => {
    const newVariations = question.variations.filter((_, i) => i !== index);
    onChange({ ...question, variations: newVariations });
  };

  const handleYesChange = (updatedYes) => {
    onChange({ ...question, yes: updatedYes });
  };

  const addYesBranch = () => {
    if (question.variations && question.variations.length === 0) {
      onChange({
        ...question,
        yes: null,
      });
    } else {
      onChange({
        ...question,
        yes: {
          text: "",
          type: "Expression",
          variable: "Yes",
          variations: [],
          yes: null,
        },
      });
    }
  };

  const removeYesBranch = () => {
    onChange({ ...question, yes: null }); // Simplified removeYesBranch
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          {label}
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          value={question.text || ""}
          onChange={(e) => onChange({ ...question, text: e.target.value })}
          sx={{ mb: 2 }}
          label="Question Text"
        />

        {/* Type Selection */}
        <Box mb={2}>
          <Typography variant="subtitle1">Type</Typography>
          <FormControl fullWidth>
            <Select value={question.type || ""} onChange={handleTypeChange}>
              <MenuItem value="question">Question</MenuItem>
              <MenuItem value="expression">Expression</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Variable Selection */}
        <Box mb={2}>
          <Typography variant="subtitle1">Variable</Typography>
          <FormControl fullWidth>
            <Select
              value={question.variable || ""}
              onChange={handleVariableChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Variations Section */}
        <Box mb={2}>
          <Typography variant="subtitle1">Variations</Typography>
          {question.variations.map((variation, index) => (
            <Grid
              container
              spacing={1}
              alignItems="center"
              key={index}
              sx={{ mb: 1 }}
            >
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={variation.text}
                  onChange={(e) => handleVariationChange(index, e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  onClick={() => removeVariation(index)}
                  disabled={question.variations.length <= 1}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={addVariation}
            variant="contained"
            color="secondary"
            size="small"
          >
            Add Variation
          </Button>
        </Box>

        {/* Yes Branch */}
        <Box mt={2}>
          {question.yes ? (
            <Box
              sx={{ borderLeft: `2px solid red`, ml: level * 2, pl: 2, mt: 2 }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="subtitle1">
                  Close Ended Question
                </Typography>
                <IconButton onClick={removeYesBranch} color="error">
                  <RemoveIcon />
                </IconButton>
              </Box>
              <QuestionForm
                question={question.yes}
                onChange={handleYesChange}
                label="Nested Yes Question"
                level={level + 1}
              />
            </Box>
          ) : (
            <Button
              onClick={addYesBranch}
              variant="contained"
              color="secondary"
              size="small"
            >
              Add Yes Branch
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const JobRoleQuestions = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const location = useLocation();
  const [formData, setFormData] = useState({
    id: "",
    userid: "",
    username: "",
    technicalQuestions: [{ variations: [""], yes: null }],
    questions: [{ variations: [""], yes: null }],
  });

  const fetchDataWithAuth = () => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    if (!username || !password) {
      console.error("Username or password not found in localStorage");
      return;
    }

    const authHeader = "Basic " + btoa(username + ":" + password);

    axios
      .get(
        "https://management.plugleads.in/role_corpus_data/extra-details/manage_quest_details/",
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then((response) => {
        const data = response.data[0]; // Get the first object from the array
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const updateQuestion = (index, updatedQuestion) => {
    const updatedList = [...formData.questions];
    updatedList[index] = updatedQuestion;
    setFormData((prev) => ({ ...prev, questions: updatedList }));
  };

  const updateTechnicalQuestion = (index, updatedQuestion) => {
    const updatedList = [...formData.technicalQuestions];
    updatedList[index] = updatedQuestion;
    setFormData((prev) => ({ ...prev, technicalQuestions: updatedList }));
  };

  const addTechnicalQuestion = () => {
    setFormData((prev) => ({
      ...prev,
      technicalQuestions: [
        ...prev.technicalQuestions,
        { variations: [""], yes: null },
      ],
    }));
  };

  const addGeneralQuestion = () => {
    setFormData((prev) => ({
      ...prev,
      questions: [...prev.questions, { variations: [""], yes: null }],
    }));
  };

  useEffect(() => {
    fetchDataWithAuth(); // Call the function to fetch data on component mount
  }, []);

  const [requirements, setRequirements] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const navigate = useNavigate();

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "questions_pending",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        { headers }
      );

      if (
        response.data &&
        typeof response.data === "object" &&
        !Array.isArray(response.data)
      ) {
        setRequirements(
          response.data.role && response.data.role.role_requirements
            ? response.data.role.role_requirements
            : ["No Role Requirements Available"] // Default message if no role requirements
        );
        const role = {
          id: response.data.role.id,
          title: response.data.role.name || "Job Role",
          description: response.data.description || "No description provided",
          category: response.data.category || "Uncategorized",
          category: response.data.category || "Uncategorized",
          status: response.data.status || "Fetched",
        };
        setJobRoles([role]);
        setRoleId(role.id);
      } else {
        console.error("Unexpected data structure:", response.data);
        setSnackbar({
          open: true,
          message: "Unexpected data structure returned.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching job roles:", error);
      setSnackbar({
        open: true,
        message: "Error fetching job roles. Please try again later.",
        severity: "error",
      });
    }
  };

  const handlefetchrole = async () => {
    await fetchJobRoles();
    fetchDataWithAuth(); // Call the function to fetch data on component mount
  };

  const handleNext = async () => {
    const currentRole = jobRoles[currentRoleIndex];
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userid = localStorage.getItem("userid");

    const basicAuth = btoa(`${username}:${password}`);

    // Function to recursively transform question data into the required format
    const formatQuestionData = (question) => {
      if (!question) return null;

      const formattedYes = question.yes
        ? formatQuestionData(question.yes)
        : null;

      // If "yes" exists but has no variations, set it to null
      const shouldSetYesToNull =
        formattedYes &&
        (!formattedYes.variations || formattedYes.variations.length === 0);

      return {
        variations: question.variations.map((v) => v.text), // Extract only variations
        type: question.type,
        variable: question.variable,
        yes: shouldSetYesToNull ? null : formattedYes, // Set "yes" to null if no variations
      };
    };

    // Function to convert question arrays into a numbered object format
    const convertQuestions = (questionsArray) => {
      const result = {};
      questionsArray.forEach((question, index) => {
        result[index + 1] = formatQuestionData(question);
      });
      return result;
    };

    const requestData = {
      id: currentRole?.id,
      username: username,
      userid: userid,
      technical_questions: convertQuestions(formData?.technicalQuestions || []),
      questions: convertQuestions(formData?.questions || []),
    };

    console.log(requestData, "DATA");

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/questions-pending/updatequest/",
        requestData,
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Data posted successfully:", response.data);
      setFormData({
        id: "",
        userid: "",
        username: "",
        technicalQuestions: [{ variations: [""], yes: null }],
        questions: [{ variations: [""], yes: null }],
      });

      setSnackbar({
        open: true,
        message: "Questions submitted successfully!",
        severity: "success",
      });
      fetchJobRoles();

      if (currentRoleIndex < jobRoles.length - 1) {
        setCurrentRoleIndex((prevIndex) => prevIndex + 1);
      } else {
        console.log("No more job roles available.");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setSnackbar({
        open: true,
        message: "Error submitting questions. Please try again.",
        severity: "error",
      });
    }
  };

  const handlesave = async () => {
    const handleunassign = async () => {
      // Fetch username and password from localStorage
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");

      // API endpoint
      const url =
        "https://management.plugleads.in/role_corpus_data/unassign-role/unassign_role/";

      try {
        // Make a POST request with Basic Auth
        const response = await axios.post(
          url,
          { role_id: roleId }, // Body can be left empty if not needed
          {
            auth: {
              username,
              password,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Roles unassigned successfully:", response.data);
        return response.data;
      } catch (error) {
        handleError(error);
      }
    };
    const handleError = (error) => {
      // Assuming error.response?.data contains an object with a `detail` property
      const errorDetail = error.response?.data?.detail || error.message; // Get the specific message

      console.error("Error unassigning roles:", errorDetail);
    };
    await handleunassign();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const currentRole = jobRoles[currentRoleIndex] || null;

  return (
    <Box style={{ padding: "10px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          height: "fit-content",
          gap: "20px",
        }}
      >
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          style={{ marginBottom: "0px", fontSize: "23px" }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent", // Disables hover background
            },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>{" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            onClick={handlefetchrole}
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "50px",
              padding: "8px 16px",
              borderColor: "#00796b",
              color: "#00796b",
              "&:hover": {
                backgroundColor: "#00796b",
                color: "#fff",
              },
            }}
          >
            Fetch Job Role
          </Button>
          <Button
            onClick={handlesave}
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "50px",
              padding: "8px 16px",
              borderColor: "#00796b",
              color: "#00796b",
              "&:hover": {
                backgroundColor: "#00796b",
                color: "#fff",
              },
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
      {currentRole && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            gap: 1,
            margin: "auto",
          }}
        >
          {/* Left Box */}

          <Box
            sx={{
              width: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontSize: "33px",
                fontWeight: "900px",
                color: "DarkBlue",
              }}
            >
              {currentRole.title}
            </Typography>

            <Typography
              sx={{
                marginTop: "20px",
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "-5px",
              }}
            >
              Requirements
            </Typography>
            <Box
              sx={{
                marginTop: 0,
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                mt: 2,
              }}
            >
              {requirements && requirements.length > 0 ? (
                requirements.map((requirement, index) => (
                  <Chip key={index} label={requirement} variant="outlined" />
                ))
              ) : (
                <Typography sx={{ fontSize: "16px", color: "gray" }}>
                  No requirements available.
                </Typography>
              )}
            </Box>
          </Box>
          {/* Right Box */}
          <Box>
            <Box mt={4}>
              <Typography variant="h5" gutterBottom sx={{ color: "#7b4397" }}>
                Technical Questions
              </Typography>
              {formData.technicalQuestions.map((question, index) => (
                <QuestionForm
                  key={index}
                  question={question}
                  onChange={(updatedQuestion) =>
                    updateTechnicalQuestion(index, updatedQuestion)
                  }
                  label={`Technical Question ${index + 1}`}
                  level={1}
                />
              ))}
              <Box textAlign="center" mt={2}>
                <StyledButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addTechnicalQuestion}
                  sx={{ backgroundColor: "#7b4397" }}
                >
                  Add Technical Question
                </StyledButton>
              </Box>
            </Box>

            <Box mt={4}>
              <Typography variant="h5" gutterBottom sx={{ color: "#7b4397" }}>
                Project Questions
              </Typography>
              {formData.questions.map((question, index) => (
                <QuestionForm
                  key={index}
                  question={question}
                  onChange={(updatedQuestion) =>
                    updateQuestion(index, updatedQuestion)
                  }
                  label={`General Question ${index + 1}`}
                  level={1}
                />
              ))}
              <Box textAlign="center" mt={2}>
                <StyledButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addGeneralQuestion}
                  sx={{ backgroundColor: "#7b4397" }}
                >
                  Add Project Question
                </StyledButton>
              </Box>
            </Box>

            <Box mt={4} textAlign="center">
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleNext}
                startIcon={<SendIcon />}
                sx={{
                  background: "linear-gradient(90deg, #dc2430, #7b4397)",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                Submit Payload
              </StyledButton>
            </Box>
          </Box>
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor:
              snackbar.severity === "success" ? "green" : "tomato",
          },
        }}
      />
    </Box>
  );
};

export default JobRoleQuestions;
