import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  FormControl,
  CardContent,
  InputLabel,
  Container,
  Divider,
  MenuItem,
  Select,
  Grid,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DisplayGeneralQuestions from "./DisplayGeneralQuestions";
import { useNavigate } from "react-router-dom";

// A recursive component for editing a single question block
const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: "#fff",
  boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 3),
}));

const QuestionForm = ({ question, onChange, label, level = 1 }) => {
  const handleTypeChange = (event) => {
    onChange({ ...question, type: event.target.value });
  };

  const handleVariableChange = (event) => {
    onChange({ ...question, variable: event.target.value });
  };

  const handleVariationChange = (index, field, value) => {
    const newVariations = [...question.variations];
    newVariations[index] = { ...newVariations[index], [field]: value };
    onChange({ ...question, variations: newVariations });
  };

  const addVariation = () => {
    onChange({
      ...question,
      variations: [
        ...question.variations,
        { text: "", type: "", variable: "" },
      ],
    });
  };

  const removeVariation = (index) => {
    const newVariations = question.variations.filter((_, i) => i !== index);
    onChange({ ...question, variations: newVariations });
  };

  const handleYesChange = (updatedYes) => {
    onChange({ ...question, yes: updatedYes });
  };

  const addYesBranch = () => {
    onChange({
      ...question,
      yes: {
        text: "",
        type: "",
        variable: "",
        variations: [{ text: "", type: "", variable: "" }],
      },
    });
  };

  const removeYesBranch = () => {
    const updatedQuestion = { ...question };
    delete updatedQuestion.yes;
    onChange(updatedQuestion);
  };

  return (
    <StyledCard variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ color: "#dc2430" }}>
          {label}
        </Typography>

        {/* Question Text Input */}
        <TextField
          fullWidth
          variant="outlined"
          value={question.text || ""}
          onChange={(e) => onChange({ ...question, text: e.target.value })}
          sx={{ mb: 2 }}
        />

        {/* Type Selection */}
        <Box mb={2}>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Type
          </Typography>
          <FormControl fullWidth>
            <Select value={question.type || ""} onChange={handleTypeChange}>
              <MenuItem value="Question">Question</MenuItem>
              <MenuItem value="Expression">Expression</MenuItem>
              <MenuItem value="Boolean">Boolean</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Variable Selection */}
        <Box mb={2}>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Variable
          </Typography>
          <FormControl fullWidth>
            <Select
              value={question.variable || ""}
              onChange={handleVariableChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Variations Section */}
        <Box mb={2}>
          <Typography variant="subtitle1" sx={{ color: "#555" }}>
            Variations
          </Typography>
          {question.variations.map((variation, index) => (
            <Grid
              container
              spacing={1}
              alignItems="center"
              key={index}
              sx={{ mb: 1 }}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={variation.text}
                  onChange={(e) =>
                    handleVariationChange(index, "text", e.target.value)
                  }
                />
              </Grid>

              {/* Type Selection for Variation */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Select
                    value={variation.type || ""}
                    onChange={(e) =>
                      handleVariationChange(index, "type", e.target.value)
                    }
                  >
                    <MenuItem value="Question">Question</MenuItem>
                    <MenuItem value="Expression">Expression</MenuItem>
                    <MenuItem value="Boolean">Boolean</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Variable Selection for Variation */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Select
                    value={variation.variable || ""}
                    onChange={(e) =>
                      handleVariationChange(index, "variable", e.target.value)
                    }
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} textAlign="right">
                <IconButton
                  onClick={() => removeVariation(index)}
                  disabled={question.variations.length <= 1}
                  sx={{ color: "#dc2430" }}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <StyledButton
            startIcon={<AddIcon />}
            onClick={addVariation}
            variant="contained"
            color="secondary"
            size="small"
            sx={{ mt: 1 }}
          >
            Add Variation
          </StyledButton>
        </Box>

        {/* Yes Branch Logic */}
        <Box mt={2}>
          {question.yes ? (
            <Box
              sx={{
                borderLeft: `2px solid #dc2430`,
                ml: level * 2,
                pl: 2,
                mt: 2,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="subtitle1" sx={{ color: "#555" }}>
                  Close Ended Question
                </Typography>
                <IconButton onClick={removeYesBranch} sx={{ color: "#dc2430" }}>
                  <RemoveIcon />
                </IconButton>
              </Box>
              <QuestionForm
                question={question.yes}
                onChange={handleYesChange}
                label="Nested Yes Question"
                level={level + 1}
              />
            </Box>
          ) : (
            <StyledButton
              onClick={addYesBranch}
              variant="contained"
              color="secondary"
              size="small"
            >
              Add Yes Branch
            </StyledButton>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};
// Main component for building resume questions
const GeneralQuestions = () => {
  // Initialize state for each section.
  const [formData, setFormData] = useState({
    contact_details: [{ variations: [""], yes: null }],
    education_details: [{ variations: [""], yes: null }],
    certification_details: [{ variations: [""], yes: null }],
    preference_details: [{ variations: [""], yes: null }],
    personal_details: [{ variations: [""], yes: null }],
  });
  const navigate = useNavigate();
  const [edit, setEdit] = useState();

  // Update a section's question by index.
  const updateSectionQuestion = (section, index, updatedQuestion) => {
    const updatedList = [...formData[section]];
    updatedList[index] = updatedQuestion;
    setFormData((prev) => ({ ...prev, [section]: updatedList }));
  };

  // Add a new question to a section.
  const addSectionQuestion = (section) => {
    setFormData((prev) => ({
      ...prev,
      [section]: [...prev[section], { variations: [""], yes: null }],
    }));
  };

  // Convert an array of questions into an object with keys "1", "2", ... (without modifying content).
  const convertQuestionsArrayToObject = (questionsArray) => {
    const result = {};
    questionsArray.forEach((q, index) => {
      result[index + 1] = q;
    });
    return result;
  };

  // Submit handler: builds the final payload.
  const handleSubmit = async () => {
    const payload = {
      contact_details: convertQuestionsArrayToObject(formData.contact_details),
      education_details: convertQuestionsArrayToObject(
        formData.education_details
      ),
      certification_details: convertQuestionsArrayToObject(
        formData.certification_details
      ),
      preference_details: convertQuestionsArrayToObject(
        formData.preference_details
      ),
      personal_details: convertQuestionsArrayToObject(
        formData.personal_details
      ),
    };
    console.log("Final Payload:", JSON.stringify(payload, null, 2));

    try {
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");
      const basicAuth = btoa(`${username}:${password}`);
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/extra-details/manage_quest_details/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${basicAuth}`,
          },
        }
      );
      console.log("API POST response:", response.data);
      // Optionally, show a success notification or perform further actions here.
    } catch (error) {
      console.error("Error posting payload:", error);
      // Optionally, show an error notification.
    }
  };

  // Helper to render a section.
  const renderSection = (sectionKey, sectionLabel) => {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ color: "#1976d2", mb: 2 }}>
          {sectionLabel}
        </Typography>
        {formData[sectionKey].map((question, index) => (
          <QuestionForm
            key={index}
            question={question}
            onChange={(updatedQuestion) =>
              updateSectionQuestion(sectionKey, index, updatedQuestion)
            }
            label={`${sectionLabel} Question ${index + 1}`}
            level={1}
          />
        ))}
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => addSectionQuestion(sectionKey)}
            color="secondary"
          >
            Add {sectionLabel} Question
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {" "}
      {/* Use Container for better layout */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <IconButton
            onClick={() => navigate("/dashboard")}
            color="primary"
            sx={{
              fontSize: "23px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <ArrowBackIcon /> Back To Dashboard
          </IconButton>
          {!edit && (
            <Button
              onClick={() => setEdit(true)}
              variant="contained" // Use contained button for emphasis
              color="primary"
              sx={{
                borderRadius: "50px",
                padding: "8px 16px",
                mb: 2,
                // ... (remove unnecessary styling - let MUI handle it)
              }}
            >
              Edit
            </Button>
          )}
        </Box>
        {!edit && <DisplayGeneralQuestions />}{" "}
        {/* Display component conditionally */}
      </Box>
      {edit && (
        <Paper
          sx={{
            p: 3,
            borderRadius: 3,
            background: "linear-gradient(135deg, #F1F8E9, #E3F2FD)",
            boxShadow: 6,
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ mb: 3, color: "#0D47A1", textAlign: "center" }} // Center the title
          >
            Resume Questions Builder
          </Typography>
          {renderSection("contact_details", "Contact Details")}
          {renderSection("education_details", "Education Details")}
          {renderSection("certification_details", "Certification Details")}
          {renderSection("preference_details", "Preference Details")}
          {renderSection("personal_details", "Personal Details")}
          <Divider sx={{ my: 4 }} />{" "}
          {/* Add a divider before the submit button */}
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ px: 4, py: 1.5, fontSize: "1.2rem" }} // Larger font size
            >
              Submit Payload
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default GeneralQuestions;
