import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import CategoryIcon from "@mui/icons-material/Category";
import QuizIcon from "@mui/icons-material/Quiz";
import Comment from "@mui/icons-material/Comment";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import axios from "axios";

import VerifiedIcon from "@mui/icons-material/Verified";
import {
  ListAltOutlined,
  Person2Outlined,
  PersonOffOutlined,
  QuestionAnswerOutlined,
} from "@mui/icons-material";

const Dashboard = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const admin = localStorage.getItem("admin");

    const initialCards = [
      {
        title: "Role Type",
        icon: <WorkIcon sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/type",
        adminOnly: true,
      },
      {
        title: "Role Approval",
        icon: <VerifiedIcon sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/approval",
        adminOnly: true,
      },
      {
        title: "All Roles",
        icon: <ListAltOutlined sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/allroles",
        adminOnly: true,
      },

      {
        title: "Role Description",
        icon: <GroupIcon sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/roles",
      },
      {
        title: "Role Requirements",
        icon: <CategoryIcon sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/category",
      },
      {
        title: "Role Questions",
        icon: <QuizIcon sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/questions",
      },
      {
        title: "Remarks",
        icon: <Comment sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/remarks",
      },
      {
        title: "General Questions",
        icon: <QuestionAnswerOutlined sx={{ fontSize: 60, color: "#FFF" }} />,
        route: "/generalquestions",
      },
    ];

    // Filter cards based on admin status
    const filteredCards = admin
      ? initialCards // Admin gets all cards
      : initialCards.filter((card) => !card.adminOnly); // Non-admins see only non-restricted cards

    setCards(filteredCards);
  }, []);

  const handleaitrain = () => {
    navigate("/aitraining");
    console.log("ai trainning on");
  };

  const handleunassign = async () => {
    // Fetch username and password from localStorage
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    // API endpoint
    const url =
      "https://management.plugleads.in/role_corpus_data/unassign-role/unassign_all_roles/";

    try {
      // Make a POST request with Basic Auth
      const response = await axios.post(
        url,
        {}, // Body can be left empty if not needed
        {
          auth: {
            username,
            password,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Roles unassigned successfully:", response.data);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  };
  const handleError = (error) => {
    // Assuming error.response?.data contains an object with a `detail` property
    const errorDetail = error.response?.data?.detail || error.message; // Get the specific message

    console.error("Error unassigning roles:", errorDetail);
    setErrorMessage(errorDetail); // Store only the string message
    setOpen(true); // Show the snackbar
  };
  const handleCloseSnackbar = () => {
    setOpen(false); // Close the snackbar
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        width: "90%",
        margin: "auto",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          mb: 5,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#3f51b5",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          Job Role Corpus
        </Typography>{" "}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#3f51b5",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          Welcome to the Dashboard
        </Typography>
        <Button
          onClick={handleaitrain}
          variant="filled"
          sx={{
            borderRadius: "50px",
            borderColor: "#00796b",
            backgroundColor: "#00796b",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#00796b",
              color: "#fff",
            },
          }}
        >
          AI Training
        </Button>
      </Box> */}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "20px",
          alignItems: "center",
        }}
      >
        {localStorage.getItem("admin") && (
          <Button
            variant="outlined"
            sx={{
              background: "lightorange",
              textTransform: "none",
              color: "#000",
            }}
            onClick={() => {
              navigate("/admindashboard");
            }}
          >
            Create New User
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
          startIcon={<LogoutIcon />}
          sx={{
            textTransform: "none",
            padding: "8px 16px",
          }}
        >
          Log Out
        </Button>
        {localStorage.getItem("admin") && (
          <Button
            variant="contained"
            onClick={handleunassign}
            color="primary"
            sx={{
              textTransform: "none",
              padding: "8px 16px",
            }}
          >
            Unassign
          </Button>
        )}
      </Box> */}
      <Grid container spacing={3} justifyContent="center" mt={2}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              sx={{
                minHeight: 220,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "0 6px 14px rgba(0, 0, 0, 0.15)",
                borderRadius: "12px",
                padding: "20px",
                background:
                  "linear-gradient(135deg, #4A90E2 30%, #6A11CB 100%)",
                color: "#fff",
                textAlign: "center",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "40px", marginBottom: "12px" }}>
                  {card.icon}
                </div>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                    mb: 1,
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    opacity: 0.8,
                    fontSize: "14px",
                    maxWidth: "200px",
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
              <Button
                variant="contained"
                onClick={() => navigate(card.route)}
                sx={{
                  background: "rgba(255, 255, 255, 0.2)",
                  color: "#fff",
                  fontWeight: "bold",
                  borderRadius: "20px",
                  padding: "8px 20px",
                  textTransform: "none",
                  transition: "background 0.3s",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.4)",
                  },
                }}
              >
                Go to {card.title}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;
