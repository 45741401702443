import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Paper,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Importing back icon
import { useNavigate } from "react-router-dom"; // React Router for navigation
import DeleteIcon from "@mui/icons-material/Delete";

const JobRolesTable = () => {
  const [jobRoles, setJobRoles] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(""); // Error message state
  const [jobType, setJobType] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [mandatoryRequirements, setMandatoryRequirements] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState([]);
  const navigate = useNavigate();

  const fetchJobRoles = async () => {
    const usertype = localStorage.getItem("usertype");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "categorised,description_pending",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        { headers }
      );

      if (
        response.data &&
        typeof response.data === "object" &&
        !Array.isArray(response.data)
      ) {
        const role = {
          id: response.data.role.id,
          title: response.data.role.name || "Job Role",
          description:
            response.data.role.description || "No description provided",
          category: response.data.role.category || "Uncategorized",
          status: response.data.role.status || "Fetched",
        };

        setJobRoles([role]);
        setRoleId(role.id);

        // 🔹 Convert `requirements` into an array of objects
        const formattedRequirements =
          response.data.requirements?.map((req) => ({
            all_requirements: req, // Ensure structure is { all_requirements: "requirement text" }
          })) || [];

        setMandatoryRequirements(formattedRequirements);
      } else {
        console.error("Unexpected data structure:", response.data);
      }
    } catch (error) {
      handleError("Error fetching job roles. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sortedRequirements = mandatoryRequirements.sort((a, b) => {
    // Ensure `a.all_requirements` and `b.all_requirements` exist and are strings
    const reqA = a?.all_requirements ?? "";
    const reqB = b?.all_requirements ?? "";

    return reqA.localeCompare(reqB);
  });

  const handleError = (message) => {
    setError(message);

    // Clear the error after 5 seconds
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  useEffect(() => {
    console.log(mandatoryRequirements, "sdfjbwejb");
  }, []);

  const validateRequirements = () => {
    let isValid = true;
    const errors = {};

    if (!description.trim()) {
      setDescriptionError("Description cannot be empty.");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    additionalRequirements.forEach((req, index) => {
      if (!req.trim()) {
        errors[index] = "This field cannot be empty.";
        isValid = false;
      }
    });

    setFieldErrors(errors);
    return isValid;
  };

  const handleNext = async () => {
    if (!validateRequirements()) {
      handleError("Please resolve the errors before proceeding.");
      return;
    }

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const userid = localStorage.getItem("userid");
    const usertype = localStorage.getItem("usertype");
    const basicAuth = btoa(`${username}:${password}`);
    const requestData = {
      role_id: roleId, // Role ID to be sent
      requirements: additionalRequirements, // Additional requirements state
      description: description, // Description to be sent
      username,
      userid,
      category: usertype,
    };

    const headers = {
      Authorization: `Basic ${basicAuth}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/roledet/update_desc/",
        requestData,
        { headers }
      );

      setMandatoryRequirements([]);
      setDescription("");
      setAdditionalRequirements([]);
      setJobType("");
      setFieldErrors({});
      setDescriptionError("");

      setLoading(true);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        handleError(error.response.data.message);
      } else {
        handleError("There was an error posting the data. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlesave = async () => {
    const handleunassign = async () => {
      // Fetch username and password from localStorage
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");

      // API endpoint
      const url =
        "https://management.plugleads.in/role_corpus_data/unassign-role/unassign_role/";

      try {
        // Make a POST request with Basic Auth
        const response = await axios.post(
          url,

          { role_id: roleId }, // Body can be left empty if not needed
          {
            auth: {
              username,
              password,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Roles unassigned successfully:", response.data);
        return response.data;
      } catch (error) {
        handleError(error);
      }
    };
    const handleError = (error) => {
      // Assuming error.response?.data contains an object with a `detail` property
      const errorDetail = error.response?.data?.detail || error.message; // Get the specific message

      console.error("Error unassigning roles:", errorDetail);
    };
    await handleunassign();
    await fetchJobRoles();
  };
  const handlefetchrole = async () => {
    await fetchJobRoles();
  };

  const handleAddRequirement = () => {
    setAdditionalRequirements([...additionalRequirements, ""]);
    setFieldErrors({});
  };

  const handleRemoveRequirement = (index) => {
    const updatedRequirements = additionalRequirements.filter(
      (_, i) => i !== index
    );
    setAdditionalRequirements(updatedRequirements);

    const updatedErrors = { ...fieldErrors };
    delete updatedErrors[index];
    setFieldErrors(updatedErrors);
  };

  const handleAdditionalRequirementChange = (index, value) => {
    const updatedRequirements = additionalRequirements.map((req, i) =>
      i === index ? value : req
    );
    setAdditionalRequirements(updatedRequirements);

    if (value.trim()) {
      const updatedErrors = { ...fieldErrors };
      delete updatedErrors[index];
      setFieldErrors(updatedErrors);
    }
  };

  const currentRole = jobRoles[currentRoleIndex] || null;

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 3,
        backgroundColor: "#F4F6F8",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: "100%",
        height: "100%",
        margin: "auto",
      }}
    >
      {/* Header Navigation */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          marginBottom: 2,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handlefetchrole}
          sx={{
            textTransform: "none",
            padding: "6px 12px",
            fontWeight: "bold",
          }}
        >
          Fetch Job Role
        </Button>
      </Box>

      {/* Error Message */}
      {error && (
        <Typography variant="body1" color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}

      {/* Role Details Section */}
      {currentRole && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 4,
            padding: 3,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
          }}
        >
          {/* Job Role & Requirements */}
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#1565C0" }}
            >
              Job Role:{" "}
              <span style={{ color: "#2E7D32" }}>{currentRole.title}</span>
            </Typography>
            <Typography variant="h6" sx={{ marginTop: 2, fontWeight: "bold" }}>
              Requirements:
            </Typography>
            {sortedRequirements.length > 0 ? (
              <Box component="ol" sx={{ paddingLeft: 3, marginTop: 1 }}>
                {sortedRequirements.map((req, index) => (
                  <Typography key={index} component="li" variant="body1">
                    {req.all_requirements}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No requirements available
              </Typography>
            )}
          </Box>

          {/* Description & Additional Requirements */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Job Description
            </Typography>
            <TextField
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              error={!!descriptionError}
              helperText={descriptionError}
              sx={{ marginTop: 1 }}
            />

            <Typography variant="h6" sx={{ marginTop: 3 }}>
              Additional Requirements
            </Typography>
            {additionalRequirements.map((req, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  gap: 1,
                }}
              >
                <TextField
                  fullWidth
                  value={req}
                  onChange={(e) =>
                    handleAdditionalRequirementChange(index, e.target.value)
                  }
                  variant="outlined"
                  error={!!fieldErrors[index]}
                  helperText={fieldErrors[index] || ""}
                />
                <IconButton
                  color="error"
                  onClick={() => handleRemoveRequirement(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddRequirement}
              sx={{ marginTop: 2, textTransform: "none", fontWeight: "bold" }}
            >
              Add Requirement
            </Button>

            {/* Buttons */}
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button variant="contained" color="primary" onClick={handleNext}>
                Save
              </Button>
              <Button variant="outlined" color="primary" onClick={handlesave}>
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default JobRolesTable;
