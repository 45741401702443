import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import axios from "axios";

const RemarksEdit = ({ onSubmit }) => {
  // Retrieve remark from location state.
  const { state } = useLocation();
  const { remark } = state;
  const navigate = useNavigate();

  // State to track edits for technical and professional questions.
  const [techEdits, setTechEdits] = useState({});
  const [proEdits, setProEdits] = useState({});
  // Track which variations are in edit mode.
  const [techEditMode, setTechEditMode] = useState({});
  const [proEditMode, setProEditMode] = useState({});
  // For error messages.
  const [errorMessage, setErrorMessage] = useState("");

  // *** New state for adding questions ***
  const [newTechQuestions, setNewTechQuestions] = useState([]);
  const [newProjQuestions, setNewProjQuestions] = useState([]);
  const [techQuestionInput, setTechQuestionInput] = useState("");
  const [projQuestionInput, setProjQuestionInput] = useState("");

  const handleTechEditClick = (variation) => {
    setTechEditMode((prev) => ({ ...prev, [variation]: true }));
    setTechEdits((prev) => ({ ...prev, [variation]: variation })); // Initialize with original value.
  };

  const handleProEditClick = (variation) => {
    setProEditMode((prev) => ({ ...prev, [variation]: true }));
    setProEdits((prev) => ({ ...prev, [variation]: variation }));
  };

  const handleTechChange = (variation, newValue) => {
    setTechEdits((prev) => ({ ...prev, [variation]: newValue }));
  };

  const handleProChange = (variation, newValue) => {
    setProEdits((prev) => ({ ...prev, [variation]: newValue }));
  };

  const handleTechSave = (variation) => {
    setTechEditMode((prev) => ({ ...prev, [variation]: false }));
  };

  const handleProSave = (variation) => {
    setProEditMode((prev) => ({ ...prev, [variation]: false }));
  };

  // Functions to handle adding new questions
  const handleAddTechQuestion = () => {
    if (techQuestionInput.trim() === "") return;
    setNewTechQuestions((prev) => [...prev, techQuestionInput.trim()]);
    setTechQuestionInput("");
  };

  const handleAddProjQuestion = () => {
    if (projQuestionInput.trim() === "") return;
    setNewProjQuestions((prev) => [...prev, projQuestionInput.trim()]);
    setProjQuestionInput("");
  };

  const handleFinalSubmit = async () => {
    // Build changes only for variations that have been edited.
    const techChanges = {};
    Object.keys(techEdits).forEach((orig) => {
      if (techEdits[orig] !== orig) {
        techChanges[orig] = techEdits[orig];
      }
    });
    const proChanges = {};
    Object.keys(proEdits).forEach((orig) => {
      if (proEdits[orig] !== orig) {
        proChanges[orig] = proEdits[orig];
      }
    });

    // Include new questions as well.
    if (
      Object.keys(techChanges).length === 0 &&
      Object.keys(proChanges).length === 0 &&
      newTechQuestions.length === 0 &&
      newProjQuestions.length === 0
    ) {
      setErrorMessage(
        "Please make at least one edit or add a new question before submitting."
      );
      return;
    }

    const payload = {
      role_id: remark.role_id,
      userid: localStorage.getItem("userid"),
      username: localStorage.getItem("username"),
      tech_changes: techChanges,
      pro_changes: proChanges,
      new_tech_questions: newTechQuestions,
      new_project_questions: newProjQuestions,
    };

    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/approve/change_quest/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(
              `${localStorage.getItem("username")}:${localStorage.getItem(
                "password"
              )}`
            )}`,
          },
        }
      );
      console.log("API Response:", response.data);
      if (onSubmit) onSubmit(payload);
    } catch (error) {
      console.error("Error posting payload:", error);
      setErrorMessage(error.response?.data?.detail || error.message);
    }
  };

  return (
    <Paper
      sx={{
        p: 4,
        maxWidth: 900,
        mx: "auto",
        borderRadius: 3,
        background: "linear-gradient(135deg, #f5f7fa, #e0f2f1)",
        boxShadow: 6,
      }}
    >
      {/* Navigation */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
        <IconButton
          onClick={() => navigate("/dashboard")}
          sx={{
            fontSize: "26px",
            "&:hover": { backgroundColor: "transparent" },
          }}
          color="primary"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" color="primary">
          Back To Dashboard
        </Typography>
      </Box>

      {/* Title */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: "#d84315", fontWeight: "bold", mb: 2 }}
      >
        Edit Rejected Questions for {remark.role_name}
      </Typography>
      <Divider sx={{ mb: 3 }} />

      {/* Existing Technical Questions (Rejected) */}
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#0d47a1", mb: 1 }}
      >
        Technical Questions
      </Typography>
      <List>
        {remark.rej_tec_quest.map((variation, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <ListItem
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
                borderRadius: 2,
                p: 1,
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemText primary={variation} />{" "}
              {/* Use variation directly */}
              {!techEditMode[variation] && (
                <IconButton
                  onClick={() => handleTechEditClick(variation)}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              )}
            </ListItem>
            {techEditMode[variation] && (
              <Box
                sx={{
                  ml: 4,
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <TextField
                  size="small"
                  fullWidth
                  label="Edit Variation"
                  value={techEdits[variation] || variation}
                  onChange={(e) => handleTechChange(variation, e.target.value)}
                  sx={{ backgroundColor: "#e3f2fd", borderRadius: 1 }}
                />
                <IconButton
                  onClick={() => handleTechSave(variation)}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        ))}
      </List>

      {/* Section for adding new Technical Questions */}
      <Divider sx={{ my: 3 }} />
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#0d47a1", mb: 1 }}
      >
        Add Technical Questions
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
        <TextField
          fullWidth
          label="New Technical Question"
          value={techQuestionInput}
          onChange={(e) => setTechQuestionInput(e.target.value)}
        />
        <Button variant="outlined" onClick={handleAddTechQuestion}>
          Add
        </Button>
      </Box>
      {newTechQuestions.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 3 }}>
          {newTechQuestions.map((q, index) => (
            <Typography key={index} variant="body1">
              {index + 1}. {q}
            </Typography>
          ))}
        </Box>
      )}

      {/* Existing Professional (Project) Questions (Rejected) */}
      <Divider sx={{ my: 3 }} />
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#0d47a1", mb: 1 }}
      >
        Professional Questions
      </Typography>
      <List>
        {remark.rej_pro_quest.map((variation, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <ListItem
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
                borderRadius: 2,
                p: 1,
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <ListItemText primary={variation} />{" "}
              {/* Use variation directly */}
              {!proEditMode[variation] && (
                <IconButton
                  onClick={() => handleProEditClick(variation)}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              )}
            </ListItem>
            {proEditMode[variation] && (
              <Box
                sx={{
                  ml: 4,
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <TextField
                  size="small"
                  fullWidth
                  label="Edit Variation"
                  value={proEdits[variation] || variation}
                  onChange={(e) => handleProChange(variation, e.target.value)}
                  sx={{ backgroundColor: "#e3f2fd", borderRadius: 1 }}
                />
                <IconButton
                  onClick={() => handleProSave(variation)}
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        ))}
      </List>

      {/* Section for adding new Professional (Project) Questions */}
      <Divider sx={{ my: 3 }} />
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "#0d47a1", mb: 1 }}
      >
        Add Project Questions
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
        <TextField
          fullWidth
          label="New Project Question"
          value={projQuestionInput}
          onChange={(e) => setProjQuestionInput(e.target.value)}
        />
        <Button variant="outlined" onClick={handleAddProjQuestion}>
          Add
        </Button>
      </Box>
      {newProjQuestions.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 3 }}>
          {newProjQuestions.map((q, index) => (
            <Typography key={index} variant="body1">
              {index + 1}. {q}
            </Typography>
          ))}
        </Box>
      )}

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinalSubmit}
          sx={{ px: 4, py: 1.5, fontSize: "1rem" }}
        >
          Submit Edits
        </Button>
      </Box>

      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default RemarksEdit;
