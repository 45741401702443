import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, useNavigate } from "react-router-dom";
import { Person2Outlined } from "@mui/icons-material";

const NavBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user type from localStorage
    const storedUser = JSON.parse(localStorage.getItem("user")) || {};
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.clear(); // Clears localStorage
    navigate("/"); // Redirect to login or home page
  };

  const navLinks = [
    { title: "Home", path: "/dashboard", icon: <HomeIcon /> },
    { title: "Ai Training", path: "/aitraining", icon: <SmartToyIcon /> },
    { title: "Profile", path: "/profile", icon: <Person2Outlined /> },
  ];

  if (localStorage.getItem("admin")) {
    navLinks.splice(2, 0, {
      title: "Create User",
      path: "/admindashboard",
      icon: <PersonAddIcon />,
    });
  }

  return (
    <>
      {/* Desktop Navbar */}
      <AppBar
        position="static"
        sx={{
          background: "linear-gradient(45deg, #FF8C00, #8B008B)",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              color: "white",
              textTransform: "uppercase",
              letterSpacing: 1,
            }}
          >
            Job Role Corpus
          </Typography>

          {/* Desktop Links */}
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {navLinks.map((link) => (
              <Button
                key={link.title}
                color="inherit"
                component={Link}
                to={link.path}
                startIcon={link.icon}
                sx={{
                  textTransform: "none",
                  fontSize: "16px",
                  color: "white",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    transform: "scale(1.1)",
                    borderRadius: "8px",
                  },
                }}
              >
                {link.title}
              </Button>
            ))}
            {/* Log Out Button */}
            <Button
              color="inherit"
              onClick={handleLogout}
              startIcon={<ExitToAppIcon />}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                color: "white",
                fontWeight: "bold",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  transform: "scale(1.1)",
                  borderRadius: "8px",
                },
              }}
            >
              Log Out
            </Button>
          </div>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleDrawerToggle}
            sx={{
              display: { sm: "none" },
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              padding: "8px",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.4)",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            background: "linear-gradient(180deg, #8B008B, #FF8C00)",
            color: "white",
          },
        }}
      >
        <List sx={{ width: 250 }}>
          {navLinks.map((link) => (
            <ListItem
              button
              key={link.title}
              component={Link}
              to={link.path}
              onClick={handleDrawerToggle}
              sx={{
                padding: "10px 20px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>{link.icon}</ListItemIcon>
              <ListItemText primary={link.title} />
            </ListItem>
          ))}
          {/* Log Out Button for Mobile */}
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default NavBar;
