import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseUrl = "https://api.jinnhire.in/jinnhire/data/";
const getFileUrl = `${baseUrl}resume-processing/get-file/`;
const markFileCompletedUrl = `${baseUrl}resume-processing/mark-file-completed/`;

const credentials = {
  email: "mail_automation@nexiiconsulting.com",
  password: "password",
};

const handleSubmit = async (e) => {
  // API call for login
  try {
    const response = await axios.post(
      "https://api.jinnhire.in/jinnhire/data/users/login/",
      credentials
    );

    if (response.status === 200) {
      console.log("Login successful:", response.data);

      // Extract the token from the response
      const token = response.data?.token;

      if (token) {
        // Optional: Store the token in localStorage/sessionStorage
        localStorage.setItem("authToken", token);
      }
    }
  } catch (error) {
    console.error("Error during login:", error);
  }
};

const AiTraining = () => {
  const navigate = useNavigate();
  const [resumeid, setResumeId] = useState();
  const [inputText, setInputText] = useState("");
  const [inputText2, setInputText2] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [newText, setNewText] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  const [content, setContent] = useState();
  const [lines, setLines] = useState([]); // Unmapped text lines
  const [mappings, setMappings] = useState({}); // Mapped text lines organized by section
  const [selectedTextBox, setSelectedTextBox] = useState(null); // Currently selected text box
  const [copied, setCopied] = useState(false);
  const defaultPrompt =
    "Classify the given resume into the following sections:\n1) contact_details\n2) skills_details\n3) work_experience_details\n4) education_details\n5) personal_details\n6) preferences_details\n\nOutput the results in a JSON file.\nEnsure the original content remains intact. Do not modify the statements or remove any sentences.";

  const [editingText, setEditingText] = useState({}); // Track editing states
  const [selectedLineIndex, setSelectedLineIndex] = useState(null); // Currently selected line for mapping
  const [highlightedText, setHighlightedText] = useState(""); // Text to highlight
  const [options] = useState([
    "Contact Information",
    "Professional Summary / Objective",
    "Core Skills / Key Competencies",
    "Work Experience",
    "Projects",
    "Education",
    "Certifications",
    "Awards and Achievements",
    "Languages",
    "Hobbies and Interests",
    "References",
    "Volunteer Experience",
    "Technical Summary",
    "Declaration",
    "Bucket",
  ]); // Dropdown options
  const [feedback, setFeedback] = useState({ message: "", type: "" }); // Feedback messages
  const [fileName, setFileName] = useState(""); // Store the actual file name
  const token = localStorage.getItem("authToken");
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [isTextHighlighted, setIsTextHighlighted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); // Selected dropdown option
  const [highlightedMapping, setHighlightedMapping] = useState({});

  // Fetch text data (simulate with static data for demo)
  useEffect(() => {
    // fetchData();
    handleSubmit();
  }, []);

  const handleCopy = () => {
    const textToCopy = `${defaultPrompt}\n\n${content || ""}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleDoubleClick = (lineIndex) => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim(""); // Get the selected text

    if (selectedText) {
      console.log("Selected text:", selectedText);
      setHighlightedText(selectedText); // Set the highlighted text
      setSelectedTextBox(lineIndex);
    } else {
      console.log("No text selected. Defaulting to full line.");
      setHighlightedText(lines[lineIndex]); // Default to the full line if no text is selected
    }
  };

  const handlePost = async () => {
    setIsPosting(true);
    try {
      const response = await fetch("https://your-api-endpoint.com/post", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: inputText }),
      });
      if (response.ok) {
        alert("Data successfully posted!");
      } else {
        alert("Failed to post data");
      }
    } catch (error) {
      alert("Error posting data");
    } finally {
      setIsPosting(false);
    }
  };

  const getHighlightedContent = (content, highlightedText) => {
    if (highlightedText) {
      // Escape special characters in the highlightedText for regex
      const escapedText = highlightedText.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );

      // Create a case-insensitive regex to find the first occurrence of the highlightedText
      const regex = new RegExp(`(${escapedText})`, "i");

      // Process the content to highlight only the first occurrence of the regex match
      let highlightedContent = [];
      let lastIndex = 0;
      let matchFound = false; // Track if we've highlighted already

      content.replace(regex, (match, p1, offset) => {
        if (!matchFound) {
          // Add the part before the match
          if (lastIndex < offset) {
            highlightedContent.push(
              <span
                key={`normal-${lastIndex}`}
                style={{ backgroundColor: "transparent" }}
              >
                {content.slice(lastIndex, offset)}
              </span>
            );
          }

          // Add the highlighted part (the selected text)
          highlightedContent.push(
            <span
              key={`highlighted-${offset}`}
              style={{
                backgroundColor: "yellow", // Highlighted part background
                color: "black",
                fontWeight: "bold",
              }}
            >
              {match}
            </span>
          );

          lastIndex = offset + match.length;
          matchFound = true; // Stop after the first highlight
        }

        return match;
      });

      // Add the remaining part of the content after the last match
      if (lastIndex < content.length) {
        highlightedContent.push(
          <span
            key={`normal-${lastIndex}`}
            style={{ backgroundColor: "transparent" }}
          >
            {content.slice(lastIndex)}
          </span>
        );
      }

      return <>{highlightedContent}</>;
    }

    // If no highlightedText is provided, return the content as is
    return <span style={{ backgroundColor: "transparent" }}>{content}</span>;
  };

  const fetchData = async () => {
    if (token) {
      const fileData = await getNextFile(token);
      if (fileData) {
        const content = fileData.content;
        setContent(content);
        setFileName(fileData.file_name); // Set the actual file name

        const processedLines = [];

        // Regex patterns for both conditions
        const condition1Pattern = /\n{2,}[:\-]\n{2,}/; // Condition 1: 2+ \n before and after ":" or ":-"
        const condition2Pattern = /^\n{2,}[\s\S]*?\n{2,}$/; // Condition 2: Starts and ends with 2+ \n

        // Split the content into sections by 2 or more newlines
        const sections = content.split(/\n{2,}/);

        sections.forEach((section) => {
          const trimmedSection = section.trim();

          // Check for condition 1: ":" or ":-" with 2+ newlines before and after
          if (condition1Pattern.test(trimmedSection)) {
            processedLines.push(trimmedSection.replace(/\n+/g, " ").trim());
          }
          // Check for condition 2: Starts and ends with 2+ newlines
          else if (condition2Pattern.test(`\n${trimmedSection}\n`)) {
            processedLines.push(trimmedSection.replace(/\n+/g, " ").trim());
          } else {
            // For all other cases, treat as a regular paragraph
            processedLines.push(trimmedSection.replace(/\n+/g, " ").trim());
          }
        });

        // Update the state with the processed lines
        setLines(processedLines);
      }
    }
  };

  const handleDelete = (index) => {
    // Remove the line from the 'lines' array
    setLines((prevLines) => prevLines.filter((_, i) => i !== index));
  };

  const getNextFile = async (token) => {
    try {
      const response = await fetch(getFileUrl, {
        method: "GET",
        headers: { Authorization: `Token ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(`Fetched File: ${data.file_name}`);
        setResumeId(data.resume_id);
        return data;
      } else if (response.status === 204) {
        console.log("No files available for processing.");
      } else {
        const errorData = await response.json();
        console.error(
          `Failed to fetch file: ${response.status} - ${JSON.stringify(
            errorData
          )}`
        );
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
    return null;
  };

  const handlefetchfile = () => {
    fetchData();
  };

  const handleSelect = (lineIndex, section) => {
    const selectedLine = lines[lineIndex];

    setMappings((prevMappings) => {
      const updatedMappings = { ...prevMappings };

      if (!updatedMappings[section]) {
        updatedMappings[section] = [];
      }

      updatedMappings[section].push(selectedLine);

      return updatedMappings;
    });

    setLines((prevLines) =>
      prevLines.filter((_, index) => index !== lineIndex)
    );

    setSelectedTextBox(null);
  };

  const handleTextBoxClick = (index) => {
    const selection = window.getSelection();
    const selectedText = selection.toString(); // Get the selected text as a string

    if (selectedText) {
      console.log("Selected text:", selectedText);

      // Set the highlighted text
      setHighlightedText(selectedText);
      setSelectedTextBox(index);
    }
  };

  const handleEdit = (section, lineIndex) => {
    setEditingText((prev) => ({
      ...prev,
      [`${section}-${lineIndex}`]: mappings[section][lineIndex],
    }));
  };

  const handleSave = (section, lineIndex) => {
    const editedText = editingText[`${section}-${lineIndex}`];
    setMappings((prevMappings) => {
      const updatedMappings = { ...prevMappings };
      updatedMappings[section][lineIndex] = editedText;
      return updatedMappings;
    });
    setEditingText((prev) => {
      const updatedEditingText = { ...prev };
      delete updatedEditingText[`${section}-${lineIndex}`];
      return updatedEditingText;
    });
  };

  async function markFileCompleted(token, fileName) {
    try {
      const response = await fetch(markFileCompletedUrl, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file_name: fileName,
          section_name: options,
          mapped_data: mappings,
          resume_id: resumeid,
        }),
      });

      if (response.ok) {
        console.log(`File marked as completed: ${fileName}`);
        setMappings("");
        fetchData();
      } else {
        const errorData = await response.json();
        console.error(
          `Failed to mark file as completed: ${
            response.status
          } - ${JSON.stringify(errorData)}`
        );
      }
    } catch (error) {
      console.error("Error marking file section as completed:", error);
    }
  }

  const handleTextHighlight = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();

    if (selectedText.length > 0) {
      setIsTextHighlighted(true);

      // Get bounding rectangle of the selected text
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Calculate button position relative to the parent container
      setButtonPosition({
        top: rect.top + window.scrollY + rect.height + 5, // Offset by 5px
        left: rect.left + window.scrollX,
      });
    } else {
      setIsTextHighlighted(false);
    }
  };

  const handleAction = async () => {
    fetchData();
  };

  const handleAddText = () => {
    if (newText.trim()) {
      // Check if the section exists in mappings, if not initialize it
      if (!mappings[selectedSection]) {
        mappings[selectedSection] = [];
      }

      // Add the new text to the selected section
      mappings[selectedSection].push(newText);

      setNewText("");
      setShowTextField(false);
      setSelectedSection("");
    }
  };
  const handleOptionSelect = (event) => {
    const selectedValue = event.target.value; // Selected section
    setSelectedOption(selectedValue);

    const selection = window.getSelection();
    const selectedText = selection.toString(); // Get the selected text

    if (selectedText) {
      setMappings((prevMappings) => {
        const updatedMappings = { ...prevMappings };

        // If the section doesn't exist, initialize it
        if (!updatedMappings[selectedValue]) {
          updatedMappings[selectedValue] = [];
        }

        // Check if the selected text is already in the section
        if (!updatedMappings[selectedValue].includes(selectedText)) {
          updatedMappings[selectedValue].push(selectedText); // Append the selected text
        }

        return updatedMappings;
      });

      // Clear the selection after mapping
      window.getSelection().removeAllRanges();
      setIsTextHighlighted(false);
      setSelectedOption(""); // Reset the state to hide the dropdown
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
          sx={{
            marginTop: "14px",
            color: "white",
            textTransform: "none",
            padding: "10px",
            borderRadius: "3px",
            backgroundColor: "rgb(34,58,80,0.9)",
          }}
        >
          <HomeIcon />
        </Button>
        <Button
          sx={{
            backgroundColor: "tomato",
            color: "white",
            textTransform: "none",
            mt: 3,
          }}
          onClick={() => handlefetchfile()}
        >
          Fetch Resume
        </Button>
      </Box>
      <Box
        mt={6}
        p={3}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            gap: 4,
          }}
        >
          {/* Refer Resume Section */}
          <Box
            sx={{
              p: 2,
              border: "1px solid lightgray",
              borderRadius: "8px",
              backgroundColor: "#F9FAFC",
              width: "50%",
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
                paddingBottom: "8px",
                borderBottom: "2px solid lightblue",
                marginBottom: "1rem",
              }}
            >
              Refer Resume Section
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "8px",
                fontStyle: "italic",
                color: "gray",
              }}
            >
              {defaultPrompt}
            </Typography>
            {content ? (
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  backgroundColor: "#E5F2FF",
                  borderRadius: "8px",
                  padding: "10px",
                  border: "1px solid lightblue",
                  lineHeight: "1.6",
                  fontSize: "12px",
                }}
              >
                {content}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{ color: "gray", textAlign: "center" }}
              >
                No content available to refer yet.
              </Typography>
            )}
            <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
              <IconButton
                onClick={handleCopy}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: copied ? "green" : "gray",
                }}
              >
                {copied ? <CheckIcon /> : <ContentCopyIcon />}
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              width: "50%",
              height: "100vh",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handlePost}
              disabled={isPosting}
              sx={{ marginTop: "1rem" }}
            >
              {isPosting ? "Posting..." : "Post Data"}
            </Button>{" "}
            <TextField
              label="Enter Data"
              multiline
              rows={20}
              variant="outlined"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              sx={{ marginTop: "1rem", width: "100%", height: "100vh" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              width: "50%",
              height: "100vh",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handlePost}
              disabled={isPosting}
              sx={{ marginTop: "1rem" }}
            >
              {isPosting ? "Posting..." : "Post Data"}
            </Button>{" "}
            <TextField
              label="Enter Data"
              multiline
              rows={20}
              variant="outlined"
              value={inputText2}
              onChange={(e) => setInputText2(e.target.value)}
              sx={{ marginTop: "1rem", width: "100%", height: "100vh" }}
            />
          </Box>

          {/* <Box sx={{ flex: 1, width: "32%" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                marginLeft: "20px",
                width: "fit-content",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid lightblue",
                fontWeight: "bold",
                background: "linear-gradient(to bottom, #ff8a00, #e52e71)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginBottom: "1.5rem",
              }}
            >
              Unmapped Sections
            </Typography>
            <Grid container spacing={2}>
              {lines.map((line, index) => (
                <Grid item xs={12} key={index}>
                  <Card>
                    <IconButton
                      onClick={() => handleDelete(index)}
                      sx={{ justifySelf: "flex-end", float: "right" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <CardContent>
                      <TextField
                        sx={{ width: "100%", marginBottom: "10px" }}
                        value={line}
                        variant="outlined"
                        onClick={() => handleTextBoxClick(index)}
                        onDoubleClick={() => handleDoubleClick(index)}
                      />
                      {selectedTextBox === index && (
                        <Select
                          sx={{
                            width: "100%",
                            border: "1px solid lightblue",
                            backgroundColor: "#E5F2FF",
                          }}
                          value={selectedOption}
                          onChange={(e) => handleSelect(index, e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Select Section
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box> */}
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ width: "30%", margin: "auto" }}
          onClick={handleSubmit}
          disabled={lines.length > 0}
        >
          Submit
        </Button>

        <Snackbar
          open={!!feedback.message}
          autoHideDuration={3000}
          onClose={() => setFeedback({ message: "", type: "" })}
        >
          <Alert
            severity={feedback.type}
            onClose={() => setFeedback({ message: "", type: "" })}
          >
            {feedback.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default AiTraining;
