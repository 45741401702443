import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
} from "@mui/material";

// Recursive helper to render a single question object.
const renderQuestionData = (question, level = 1) => {
  return (
    <Box sx={{ ml: level * 2, mt: 1, width: "80%" }}>
      <List dense>
        {question.variations.map((variation, index) => (
          <ListItem key={index} sx={{ pl: level }}>
            <ListItemText
              primary={variation}
              primaryTypographyProps={{ variant: "body1", color: "#424242" }}
            />
          </ListItem>
        ))}
      </List>
      {question.yes && (
        <Box sx={{ borderLeft: "2px dashed #1976d2", pl: 2, mt: 1 }}>
          {renderQuestionData(question.yes, level + 1)}
        </Box>
      )}
    </Box>
  );
};

// Helper to render an entire section (e.g., contact_details, education_details, etc.)
const renderSection = (sectionData, sectionLabel) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography
        variant="h5"
        sx={{
          color: "#0D47A1",
          mb: 2,
          borderBottom: "2px solid #0D47A1",
          pb: 1,
        }}
      >
        {sectionLabel}
      </Typography>
      {Object.keys(sectionData).map((key) => (
        <Box key={key} sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: "#1976d2", mb: 1 }}
          >
            Question {key}:
          </Typography>
          {renderQuestionData(sectionData[key], 1)}
          <Divider sx={{ my: 1 }} />
        </Box>
      ))}
    </Box>
  );
};

const DisplayResumeData = ({ data }) => {
  return (
    <Paper
      sx={{
        p: 4,
        width: "80%",
        mx: "auto",
        borderRadius: 3,
        background: "linear-gradient(135deg, #E3F2FD, #F1F8E9)",
        boxShadow: 6,
      }}
    >
      <Typography
        variant="h4"
        sx={{ mb: 4, textAlign: "center", color: "#0D47A1" }}
      >
        Resume Data
      </Typography>
      {renderSection(data.contact_details, "Contact Details")}
      {renderSection(data.education_details, "Education Details")}
      {renderSection(data.certification_details, "Certification Details")}
      {renderSection(data.preference_details, "Preference Details")}
      {renderSection(data.personal_details, "Personal Details")}
    </Paper>
  );
};

const DisplayGeneralQuestions = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const username = localStorage.getItem("username") || "demoUser";
    const password = localStorage.getItem("password") || "demoPass";
    const authHeader = btoa(`${username}:${password}`);

    fetch(
      "https://management.plugleads.in/role_corpus_data/extra-details/manage_quest_details/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${authHeader}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        // Assuming the API returns an array with one object.
        setData(json[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching resume data:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box sx={{ textAlign: "center", mt: 6 }}>
        <Typography variant="h6">No data available.</Typography>
      </Box>
    );
  }

  return <DisplayResumeData data={data} />;
};

export default DisplayGeneralQuestions;
