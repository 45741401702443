import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  Switch,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

const RoleTable = () => {
  const [roles, setRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleName, setRoleName] = useState("");
  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const [isEditing, setIsEditing] = useState(false);
  const [editingRole, setEditingRole] = useState(null);
  const [category, setCategory] = useState(roles.category || "");
  const basicAuth = btoa(`${username}:${password}`);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fetchRoles = async () => {
    const category = localStorage.getItem("usertype");
    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/random-role/get_all_roles/",
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/json",
            category: category,
          },
        }
      );
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      showSnackbar("Error fetching roles.", "error");
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleEditClick = (role, event) => {
    event.stopPropagation();
    setEditingRole(role.id);
    setCategory(role.category || "");
  };

  const handleSaveClick = async () => {
    if (!editingRole) return;
    const roleToUpdate = roles.find((role) => role.id === editingRole);
    if (!roleToUpdate) {
      console.error("Role to update not found!");
      showSnackbar("Role to update not found!", "error");
      return;
    }
    const payload = {
      role_id: editingRole,
      category: category.trim().toUpperCase(),
    };
    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/random-role/change_category/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${basicAuth}`,
          },
        }
      );
      console.log("Category updated successfully:", response.data);
      setEditingRole(null);
      setCategory("");
      fetchRoles();
      showSnackbar("Category updated successfully.", "success");
    } catch (error) {
      console.error("Error updating category:", error);
      console.error("API Error Response:", error.response);
      showSnackbar("Error updating category.", "error");
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleToggleAssign = async (role) => {
    try {
      const updatedRole = { ...role, assigned: !role.assigned };
      await axios.post(
        "https://management.plugleads.in/role_corpus_data/random-role/update_priority/",
        { role_name: role.name },
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRoles((prevRoles) =>
        prevRoles.map((r) => (r.id === role.id ? updatedRole : r))
      );
      fetchRoles();
      showSnackbar("Role assignment updated.", "success");
    } catch (error) {
      console.error("Error updating role assignment:", error);
      showSnackbar("Error updating role assignment.", "error");
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (role) => {
    if (!editingRole) {
      setSelectedRole(role);
    }
  };

  const handleCloseModal = () => {
    setSelectedRole(null);
    setEditingRole(null);
  };

  const filteredRoles = roles.filter((role) =>
    role.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleAddRole = async () => {
    if (!roleName.trim()) return;
    const newRole = {
      job_role: roleName,
    };

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const basicAuth = btoa(`${username}:${password}`);
    try {
      const response = await axios.post(
        "https://management.plugleads.in/role_corpus_data/extra-details/filter_job_role_in_json/",
        newRole,
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRoles([...roles, response.data]);
      setRoleName("");
      showSnackbar("Role added successfully.", "success");
    } catch (error) {
      console.error("Error adding role:", error);
      showSnackbar("Error adding role.", "error");
    }
  };

  return (
    <Box
      sx={{
        padding: 4, // Increased padding
        borderRadius: 4, // Increased border radius
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        backgroundColor: "#f8f9fa", // Light background
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow
      }}
    >
      <Typography
        variant="h4" // Increased font size for heading
        sx={{
          marginBottom: 3,
          fontWeight: "600",
          color: "#2c3e50",
          textAlign: "center",
        }} // Improved heading style
      >
        Role Management
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 3, // Increased gap
          marginBottom: 3, // Increased margin
          width: "90%", // Increased width
        }}
      >
        <TextField
          label="Search by Role Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleSearch}
          sx={{ marginBottom: 2, width: "35%" }} // Increased width
        />

        <TextField
          label="Role Name"
          variant="outlined"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          fullWidth
          sx={{ width: "45%" }} // Increased width
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddRole}
          sx={{ padding: "12px 24px" }}
        >
          Add Role
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ borderRadius: 3, width: "90%" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#3498db" }}>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                ID
              </TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                Name
              </TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                Status
              </TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                Category
              </TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                Assigned
              </TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRoles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((role) => (
                <TableRow
                  key={role.id}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#ecf0f1" },
                  }}
                >
                  <TableCell>{role.id}</TableCell>
                  <TableCell onClick={() => handleRowClick(role)}>
                    {role.name}
                  </TableCell>
                  <TableCell onClick={() => handleRowClick(role)}>
                    <Box
                      sx={{
                        display: "inline-block",
                        padding: "6px 12px",
                        borderRadius: "16px",
                        backgroundColor:
                          role.status === "categorised" ? "#27ae60" : "#f39c12",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}
                    >
                      {role.status}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {editingRole === role.id ? (
                      <>
                        <TextField
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          placeholder="-"
                          size="small"
                        />
                        <IconButton
                          onClick={handleSaveClick}
                          color="primary"
                          size="small"
                        >
                          <SaveIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {role.category ? role.category : "-"}
                        <IconButton
                          onClick={(event) => handleEditClick(role, event)}
                          size="small"
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <TableCell>{role.assigned ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Switch
                      checked={role.priority === "High"}
                      onChange={() => handleToggleAssign(role)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRoles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ width: "90%", marginTop: 2 }}
      />

      <Dialog
        open={!!selectedRole}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        {selectedRole && (
          <>
            <DialogTitle sx={{ fontWeight: "600", fontSize: 24 }}>
              Role Details: {selectedRole.name}
            </DialogTitle>
            <DialogContent dividers>
              {/* ... (rest of the modal content) ... */}
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>ID:</strong> {selectedRole.id}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Status:</strong>{" "}
                <Box
                  sx={{
                    display: "inline-block",
                    padding: "6px 12px",
                    borderRadius: "16px",
                    backgroundColor:
                      selectedRole.status === "categorised"
                        ? "#27ae60"
                        : "#f39c12",
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "13px",
                  }}
                >
                  {selectedRole.status}
                </Box>
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Category:</strong> {selectedRole.category}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Assigned:</strong>{" "}
                {selectedRole.assigned ? "Yes" : "No"}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                <strong>Description:</strong>{" "}
                {selectedRole.description
                  ? selectedRole.description
                  : "No description available"}
              </Typography>
              {selectedRole.data_entered_by && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="h6">Data Entered By:</Typography>
                  {selectedRole.data_entered_by.map((entry, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      sx={{ marginBottom: 0.5 }}
                    >
                      <strong>{entry.action}</strong> by {entry.username}
                    </Typography>
                  ))}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RoleTable;
