import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  TextField,
  Button,
  Chip,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const JobRoleDetails = () => {
  // State for storing fetched role data.
  const [questionsData, setQuestionsData] = useState(null);
  // Stores the action chosen per variation.
  // Each entry: { action: "approved" | "rejected" | "deleted" }
  const [variationActions, setVariationActions] = useState({});
  const [roleId, setRoleId] = useState("");
  // Global rejection reason for all rejected variations.
  const [globalRejectionReason, setGlobalRejectionReason] = useState("");
  // To disable further changes after submission.
  const [submitted, setSubmitted] = useState(false);
  // Snackbar states for messages.
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  const showSnackbar = (message, severity = "info") => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const handlesave = async () => {
    const handleunassign = async () => {
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");
      const url =
        "https://management.plugleads.in/role_corpus_data/unassign-role/unassign_role/";
      try {
        const response = await axios.post(
          url,
          { role_id: roleId },
          {
            auth: { username, password },
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Roles unassigned successfully:", response.data);
        return response.data;
      } catch (error) {
        handleError(error);
      }
    };
    const handleError = (error) => {
      const errorDetail = error.response?.data?.detail || error.message;
      console.error("Error unassigning roles:", errorDetail);
      showSnackbar(errorDetail, "error");
    };
    await handleunassign();
  };
  // Fetch role details from API.
  const fetchRoleDetails = async () => {
    const usertype = localStorage.getItem("usertype") || "ADMIN";
    const username = localStorage.getItem("username") || "demoUser";
    const password = localStorage.getItem("password") || "demoPass";
    const basicAuth = btoa(`${username}:${password}`);

    const headers = {
      category: usertype,
      status: "approval_pending",
      Authorization: `Basic ${basicAuth}`,
    };

    try {
      const response = await axios.get(
        "https://management.plugleads.in/role_corpus_data/roledet/get_details/",
        { headers }
      );
      if (!response.data || !response.data.role) {
        showSnackbar("No role data found.", "error");
        return;
      }
      setQuestionsData(response.data.role);
      setRoleId(response.data.role.id);
    } catch (error) {
      console.error("Error fetching role details:", error);
      showSnackbar(error.response?.data?.detail || error.message, "error");
    }
  };

  // Initial fetch.
  useEffect(() => {
    fetchRoleDetails();
  }, []);

  // Update a variation's action.
  const updateVariationAction = (variationKey, action) => {
    if (submitted) return;
    setVariationActions((prev) => ({
      ...prev,
      [variationKey]: { action },
    }));
  };

  // Helper: Given a variation path like "technical.1.yes.variations.0" or "general.2.variations.1",
  // retrieve the corresponding variation value.
  const getVariationByPath = (variationPath) => {
    const parts = variationPath.split(".");
    const variationIndex = parseInt(parts.pop(), 10);
    parts.pop(); // Remove "variations"
    const basePath = parts.join(".");
    const questionObj = getQuestionByPath(basePath);
    if (questionObj && questionObj.variations) {
      return questionObj.variations[variationIndex];
    }
    return null;
  };

  // Helper: Given a path like "technical.1" or "general.2.yes",
  // retrieve the corresponding question object.
  const getQuestionByPath = (path) => {
    const parts = path.split(".");
    let current = null;
    if (parts[0] === "technical") {
      current = questionsData?.technical_skills;
    } else if (parts[0] === "general") {
      current = questionsData?.role_question;
    }
    for (let i = 1; i < parts.length; i++) {
      current = current[parts[i]];
      if (!current) break;
    }
    return current;
  };

  // Recursively collect all variations from a question.
  const getAllVariationsFromQuestion = (question) => {
    let variations = question.variations || [];
    if (question.yes) {
      variations = variations.concat(
        getAllVariationsFromQuestion(question.yes)
      );
    }
    return variations;
  };

  // Get all approved variations for a given category.
  const getAllApprovedVariationsByCategory = (category) => {
    let approved = [];
    const questions =
      category === "technical"
        ? questionsData?.technical_skills
        : questionsData?.role_question;
    if (questions) {
      Object.keys(questions).forEach((key) => {
        approved = approved.concat(
          getAllVariationsFromQuestion(questions[key])
        );
      });
    }
    return approved;
  };

  // Traverse the fetched questions to collect deleted variation keys in order for a category.
  const getDeletedVariationKeysInOrder = (category) => {
    const deletedKeys = [];
    const traverse = (questions, categoryPrefix) => {
      Object.keys(questions).forEach((key) => {
        const q = questions[key];
        const basePath = `${categoryPrefix}.${key}`;
        if (q.variations) {
          q.variations.forEach((_, index) => {
            const variationKey = `${basePath}.variations.${index}`;
            if (
              variationActions[variationKey] &&
              variationActions[variationKey].action === "deleted"
            ) {
              deletedKeys.push(variationKey);
            }
          });
        }
        if (q.yes) {
          traverse({ yes: q.yes }, basePath);
        }
      });
    };
    const categoryQuestions =
      category === "technical"
        ? questionsData?.technical_skills
        : questionsData?.role_question;
    if (categoryQuestions) {
      traverse(categoryQuestions, category);
    }
    return deletedKeys;
  };

  // Get deleted variations for a category in fetched order (reverse so last comes first).
  const getDeletedVariationsByCategory = (category) => {
    const deletedKeys = getDeletedVariationKeysInOrder(category);
    const reversedKeys = deletedKeys.reverse();
    return reversedKeys.map((key) => getVariationByPath(key));
  };

  // Renders a single question with its variations and nested yes branch.
  const renderQuestion = (question, path, label) => {
    return (
      <Accordion key={path} sx={{ mb: 2, borderRadius: 2, boxShadow: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          id={`panel-${path}-header`}
          sx={{
            background: "linear-gradient(90deg, #1976d2, #0d47a1)",
            color: "#fff",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6">{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ pl: 2 }}>
            <Typography variant="body1" gutterBottom>
              Variations:
            </Typography>
            <List>
              {(question.variations || []).map((variation, index) => {
                const variationKey = `${path}.variations.${index}`;
                return (
                  <ListItem
                    key={variationKey}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                      borderRadius: 1,
                      mb: 1,
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      p: 1,
                    }}
                  >
                    <ListItemText primary={variation} />{" "}
                    {/* Fixed: Directly using variation */}
                    <Box sx={{ display: "flex", gap: 1 }}>
                      {!submitted && (
                        <>
                          <IconButton
                            size="small"
                            color={
                              variationActions[variationKey]?.action ===
                              "approved"
                                ? "success"
                                : "default"
                            }
                            onClick={() =>
                              updateVariationAction(variationKey, "approved")
                            }
                          >
                            <CheckCircleIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            color={
                              variationActions[variationKey]?.action ===
                              "deleted"
                                ? "error"
                                : "default"
                            }
                            onClick={() =>
                              updateVariationAction(variationKey, "deleted")
                            }
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            color={
                              variationActions[variationKey]?.action ===
                              "rejected"
                                ? "warning"
                                : "default"
                            }
                            onClick={() =>
                              updateVariationAction(variationKey, "rejected")
                            }
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </ListItem>
                );
              })}
            </List>
            {/* Handling Yes Branch */}
            {question.yes && (
              <Box
                sx={{
                  mt: 2,
                  ml: 2,
                  borderLeft: "2px solid #1976d2",
                  pl: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ color: "#1976d2" }}>
                  Yes Branch:
                </Typography>
                {renderQuestion(
                  question.yes,
                  `${path}.yes`,
                  "Nested Yes Question"
                )}
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  // Renders a list of top-level questions for a given category.
  const renderQuestionsList = (questions, category) => {
    if (!questions) return null;
    return Object.keys(questions).map((key) => {
      const path = `${category}.${key}`;
      return renderQuestion(questions[key], path, `Question ${key}`);
    });
  };

  // Submit handler.
  // Separates payloads by category for rejected and deleted variations.
  // If no rejections or deletions exist, all variations are approved and sent to the approve API.
  const handleSubmit = async () => {
    let rejectedTechnical = [];
    let rejectedGeneral = [];
    let deletedTechnical = [];
    let deletedGeneral = [];

    Object.keys(variationActions).forEach((variationKey) => {
      const actionData = variationActions[variationKey];
      const variationValue = getVariationByPath(variationKey);
      if (!variationValue) return;
      if (actionData.action === "rejected") {
        if (variationKey.startsWith("technical.")) {
          rejectedTechnical.push(variationValue);
        } else if (variationKey.startsWith("general.")) {
          rejectedGeneral.push(variationValue);
        }
      } else if (actionData.action === "deleted") {
        if (variationKey.startsWith("technical.")) {
          deletedTechnical.push(variationValue);
        } else if (variationKey.startsWith("general.")) {
          deletedGeneral.push(variationValue);
        }
      }
    });

    // Reverse deleted arrays so that the bottom (last) appears first.
    deletedTechnical = deletedTechnical.reverse();
    deletedGeneral = deletedGeneral.reverse();

    if (
      (rejectedTechnical.length > 0 || rejectedGeneral.length > 0) &&
      (!globalRejectionReason || globalRejectionReason.trim() === "")
    ) {
      showSnackbar(
        "Please provide a global rejection reason for all rejected variations.",
        "error"
      );
      return;
    }

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const authHeader =
      username && password ? btoa(`${username}:${password}`) : null;
    const user_id = localStorage.getItem("userid");

    try {
      if (
        rejectedTechnical.length > 0 ||
        rejectedGeneral.length > 0 ||
        deletedTechnical.length > 0 ||
        deletedGeneral.length > 0
      ) {
        const rejectedPayload = {
          user_id,
          role_id: roleId,
          approval_status: "Reject",
          username: localStorage.getItem("username"),
          rejected_technical_questions: rejectedTechnical,
          rejected_questions: rejectedGeneral,
          remarks: globalRejectionReason,
        };

        const deletedPayload = {
          userid: user_id,
          username: localStorage.getItem("username"),
          role_id: roleId,
          deleted_technical_questions: deletedTechnical,
          deleted_questions: deletedGeneral,
        };

        const response1 = await axios.post(
          "https://management.plugleads.in/role_corpus_data/approve/approve_reject/",
          rejectedPayload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authHeader}`,
            },
          }
        );
        console.log("Rejected API response:", response1.data);

        const response2 = await axios.post(
          "https://management.plugleads.in/role_corpus_data/questions-pending/del_quest/",
          deletedPayload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authHeader}`,
            },
          }
        );
        console.log("Deleted API response:", response2.data);
        showSnackbar(
          "Rejections and deletions submitted successfully!",
          "success"
        );
      } else {
        // No rejections or deletions; all variations are approved.
        const approvedTechnical =
          getAllApprovedVariationsByCategory("technical");
        const approvedGeneral = getAllApprovedVariationsByCategory("general");

        const approvedPayload = {
          user_id,
          approval_status: "Accept",
          username: localStorage.getItem("username"),
          role_id: roleId,
          approved_technical_questions: approvedTechnical,
          approved_questions: approvedGeneral,
        };

        const response3 = await axios.post(
          "https://management.plugleads.in/role_corpus_data/approve/approve_reject/",
          approvedPayload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authHeader}`, // Correct placement inside headers
            },
          }
        );
        console.log("Approved API response:", response3.data);
        showSnackbar("All questions approved successfully!", "success");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      showSnackbar(error.response?.data?.detail || error.message, "error");
    }

    setSubmitted(true);
  };

  const anyRejected = Object.values(variationActions).some(
    (actionData) => actionData.action === "rejected"
  );

  return (
    <Paper
      sx={{
        p: 3,
        maxWidth: 900,
        mx: "auto",
        borderRadius: 3,
        background: "linear-gradient(135deg, #FFECB3, #E1F5FE)",
        boxShadow: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          gap: "20px",
          mb: 3,
        }}
      >
        <IconButton
          onClick={() => navigate("/dashboard")}
          color="primary"
          sx={{
            fontSize: "23px",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <ArrowBackIcon /> Back To Dashboard
        </IconButton>
        <Button
          variant="outlined"
          color="primary"
          onClick={fetchRoleDetails}
          sx={{ textTransform: "none", padding: "2px" }}
        >
          Fetch Job Role
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlesave}
          sx={{ textTransform: "none", padding: "2px" }}
        >
          Next
        </Button>
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "#BF360C", fontWeight: "bold" }}
      >
        {questionsData?.name || "N/A"}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ mb: 2, fontSize: "1.1rem", color: "#3E2723" }}
      >
        {questionsData?.description}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: "#D84315" }}>
        Role Requirements
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
        {questionsData &&
          questionsData.role_requirements &&
          questionsData.role_requirements
            .map((req) => req.trim())
            .sort((a, b) => a.localeCompare(b))
            .map((req, index) => (
              <Chip
                key={index}
                label={req}
                color="primary"
                variant="outlined"
              />
            ))}
      </Box>
      <Typography variant="h6" gutterBottom sx={{ color: "#BF360C" }}>
        Technical Questions
      </Typography>
      {questionsData &&
        renderQuestionsList(questionsData.technical_skills, "technical")}
      <Typography variant="h6" sx={{ mt: 4, color: "#BF360C" }}>
        All Questions
      </Typography>
      {questionsData &&
        renderQuestionsList(questionsData.role_question, "general")}
      {anyRejected && (
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Global Rejection Reason for all rejected variations"
            fullWidth
            value={globalRejectionReason}
            onChange={(e) => setGlobalRejectionReason(e.target.value)}
          />
        </Box>
      )}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ px: 4, py: 1.5, fontSize: "1rem" }}
        >
          Submit
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default JobRoleDetails;
