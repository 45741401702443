import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Paper,
  Container,
} from "@mui/material";
import { AccountCircle, Work, Description } from "@mui/icons-material";

const ProfileComponent = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl =
    "https://management.plugleads.in/role_corpus_management/auth/profile/";

  const username = localStorage.getItem("username");
  const password = localStorage.getItem("password");
  const authHeader = `Basic ${btoa(username + ":" + password)}`;

  useEffect(() => {
    axios
      .get(apiUrl, { headers: { Authorization: authHeader } })
      .then((response) => {
        setUserData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setError("Error fetching data");
        setLoading(false);
      });
  }, []);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );

  return (
    <Container maxWidth="md" sx={{ marginTop: "18px" }}>
      {/* Page Title */}
      <Typography
        variant="h3"
        fontWeight="bold"
        align="center"
        color="primary"
        gutterBottom
      >
        User Profile
      </Typography>

      {/* User Info Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 4, borderRadius: 2 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          <AccountCircle sx={{ verticalAlign: "middle", mr: 1 }} />
          Username: {userData.username}
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          <Description sx={{ verticalAlign: "middle", mr: 1 }} />
          Email: {userData.email}
        </Typography>
        <Typography variant="h5" fontWeight="bold">
          <Work sx={{ verticalAlign: "middle", mr: 1 }} />
          User Type: {userData.user_type}
        </Typography>
      </Paper>

      <Divider sx={{ my: 3 }} />

      {/* Work History Section */}
      <Typography variant="h4" fontWeight="bold" color="secondary" gutterBottom>
        Work History
      </Typography>

      <Paper
        elevation={2}
        sx={{
          maxHeight: "350px",
          overflowY: "auto",
          p: 2,
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <List>
          {userData.work_history.length > 0 ? (
            userData.work_history
              .slice()
              .reverse()
              .map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    borderBottom:
                      index !== userData.work_history.length - 1
                        ? "1px solid #ddd"
                        : "none",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        <strong>Role Name:</strong> {item.role_name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {item.action} {item.work ? `- ${item.work}` : ""}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
          ) : (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center", py: 2 }}
            >
              No work history available
            </Typography>
          )}
        </List>
      </Paper>
    </Container>
  );
};

export default ProfileComponent;
